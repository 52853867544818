// eslint-disable-next-line no-redeclare,no-unused-vars
function buildRoleChanger(li) {
  li.children('.nav-link').attr({
    'data-bs-toggle': 'collapse',
    'data-bs-target': '#roleChanger-collapse',
  });
  var submenuFrame = $(
    '<div id="roleChanger-collapse" class="collapse"></div>'
  ).appendTo(li);
  var submenu = $('<ul class="sub-menu flex-column gap-1"></ul>').appendTo(
    submenuFrame
  );

  var settings = getFieldSettings({object: 'user'}, 'roll');
  $.each(settings.values, function (i, value) {
    if (inArray(value, ['auto', 'cronjob', 'superadmin', 'admin'])) return;
    var label = value;
    if (settings.labels) label = settings.labels[value];
    var li = $('<li></li>').appendTo(submenu);
    var item = $(
      '<a class="sub-link link-light fw-normal" href="javascript:void(0)" value="' +
        value +
        '">' +
        label +
        ' </a>'
    ).appendTo(li);
    item.on(click, function () {
      li.find('.menuLabel').text('Rolle: ' + label);
      $(this).addClass('active').siblings().removeClass('active');
      role = value;
      submenu.removeClass('visible');
      fieldsMenu.roleChanger.collapse = true;
      buildMenu(sidebar, true);
      ajax({
        object: 'start',
        callback: fillDashboard,
        send: {rollChange: value},
      });
      setTitle(window.titleOrig + ' - ' + label, true);
    });
    if (value == role) {
      item.addClass('active');
      li.find('.menuLabel').text('Rolle: ' + label);
    }
  });

  return submenuFrame;

  // change roll instantly
  // if (roll)
  // 	setTimeout(function () {
  // 		submenu.find('[value=' + roll + ']').trigger(click);
  // 	}, 1000);
}
